<template>

    <div id="app">
        <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
            <v-layout column>
                <div>
                    <strong>{{ snackbartest.title }}</strong>
                </div>
                <div>{{ snackbartest.text }}</div>
            </v-layout>
        </v-layout>
        <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>
        <v-data-table
    :headers="headers"
    :items="list"
    sort-by="code"
    class="elevation-1"
  >
     <template v-slot:top>
      <v-toolbar
        flat
      >
              <v-toolbar-title>LISTE INSTITUTIONS</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      color="primary"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                  >
                     
                <v-select
                 :items="listpays"
                 v-model="selectepays"
                 dense
                 item-value="id"
                 outlined
                 label="Liste des pays">
                 <template slot="selection" slot-scope="data">
                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                     {{ data.item.code_pays }} - {{ data.item.libelle }}
                    </template>
                 </v-select>

                <v-select
                 :items="listcategorie"
                 v-model="selectecategorie"
                 dense
                 item-value="sigle"
                 outlined
                 label="Liste categorie instittution">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                 </v-select>
                 <v-text-field
                      v-model="libelle"
                      label="Libellé *"
                      dense
                      outlined
                     
                    ></v-text-field>
                     <v-text-field
                      v-model="email"
                      label="email "
                      dense
                      outlined
                     
                    ></v-text-field>
                     <v-text-field
                      v-model="ifu"
                      label="ifu"
                      dense
                      outlined
                     
                    ></v-text-field>
                    <v-textarea
                      v-model="adresse"
                      label="Adresse"
                      dense
                      outlined
                    
                    ></v-textarea>
                    
                     <v-text-field
                      v-model="web"
                      label="Site web"
                      dense
                      outlined
                     
                    ></v-text-field>
                     <v-file-input
                      v-model="image"
                      label="logo"
                      dense
                      outlined
                     
                    ></v-file-input>

                   
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog=false"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.odd`]="{ item }">
       {{ item.code }} - {{ item.libelle }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="Actulisation"
      >
        Actualiser la liste
      </v-btn>
    </template>
  </v-data-table>
        

    </div>

</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
  data:() =>({
           multiLine: true,
        snackbartest: false,
        textmessage: `message`,
        
      snackbar: false,
      snackbars:false,
      text: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      textetat:false,
          headers: [
        { text: 'ID', value: 'id' ,divider: true,width: '30px'},
        { text: 'Matricule', value: 'matricule' ,divider: true,width: '50px'},
         { text: 'libelle', value: 'libelle' ,divider: true,width: '350px'},
        { text: 'Email', value: 'email' ,divider: true,width: '200px'},
        { text: 'Adresse', value: 'adresse' ,divider: true,width: '200px'},
        { text: 'Site web', value: 'web' ,divider: true,width: '150px'},
        { text: 'Pays', value: 'pays.libelle' ,divider: true,width: '100px'},
        { text: 'Logo', value: 'logo' ,divider: true,width: '100px'},
        { text: 'Actions', value: 'actions', sortable: false ,divider: true,width: '80px'},
      ],
        list:[],
        listpays:[],
        listcategorie:[],
         libelle:'',
         id:'0',
         email:'',
         matricule:'',
         ifu:'',
         logo: null,
         adresse:'',
         web:'',
         sigle:'',
         lienimage:'http://127.0.0.1:8000/storage/logo/',
         selectecategorie:'',
         selectepays:'',
         textetat:false,
         formTitle:"Formulaire d'enregistrement",

  }),
     methods: {
       //=====Afficher tout=======
       
      readAll: async function() {
       const data = await api.readAll('institution/all');
      this.list = data.list;
      },
      readAllpays: async function() {
       const data = await api.readAll('parametre/all-globale-geo');
      this.listpays = data.pays;
      },
      readCategorie: async function() {
      const data = await api.readAll('categorie-institution/all');
      this.listcategorie = data.list;
      
    },
   

   createUpdatedata: async function() {
      
      let fd=new FormData();
      //this.id_odds=this.selectodd;
      fd.append('id_pays',this.selectepays);
      fd.append('libelle',this.libelle);
      fd.append('email',this.email);
      fd.append('ifu',this.ifu);
      fd.append('adresse',this.adresse);
      fd.append('logo',this.logo);
      fd.append('web',this.web);
      fd.append('categorie',this.selectecategorie);
      
   if(this.textetat){
      
     const res=await api.createUpdatedata('institution/update/'+this.id,fd);
      if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }  
    }else{
     const res=await api.createUpdatedata('institution/add',fd);    
        if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                 //   this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
       
      }
        
        this.clear();       
        this.readAll();
      
    },
     delete: async function() {
       console.log(this.id);
     let fd=new FormData();
      fd.append('id',this.id);
       const res=await api.createUpdatedata('institution/delete',fd);   
      
     this.readAll();
    this.dialogDelete=false;
    },
    
    supdata(){
      this.delete();
      
    }, 
    save(){
      if(this.libelle==''|| this.selectepays==''|| this.selectepays=='0' || this.selectecategorie==''|| this.selectecategorie=='0'){
         this.textmessage = "Veuillez remplir les champs obligatoires";
                this.snackbartest = {
                    color: "red",
                    icon: "mdi-alert",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Erreur",
                    text: this.textmessage,
                    visible: true
                };
        return;
      }
    
      this.createUpdatedata();
      
    },
    nouveau(){
     this.clear();
     this.dialog=true;
    },
    editItem(item){
         this.libelle=item.libelle;
         this.id=item.id;
         this.email=item.email;
         this.ifu=item.ifu;
         this.adresse=item.adresse;
         this.web=item.web;
         this.textetat=true;
         //selectecategorie=item.
        // this.sigle=item.libelle;
      this.dialog=true;
    },

    deleteItem(item){
       this.id=item.id;
       this.dialogDelete=true;
      
    },

    clear(){
         this.libelle='';
         this.id='0';
         this.email='';
         this.matricule='';
         this.ifu='';
         this.logo=null;
         this.adresse='';
         this.web='';
         this.sigle='';
         this.textetat=false; 
    }

    },
   
   mounted() {
    this.readAll();
    this.readAllpays();
   this.readCategorie();
    
  },


}
</script>


<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>

